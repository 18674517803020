import React from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader'
import { SampleCardTitle } from '../../../Utils/Constants'


export default function DashboardPage() {
  return (
    <div className='page-body'>
    <Container fluid>
            <Row>
                <Col sm={12}>
                    <Card className='title-line'>
                        <CardHeader title={'DashboardPage'} />
                        <CardBody>
                        DashboardPage
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        </div>
  )
}
